import React from 'react'
import { GatsbyGraphQLType, graphql, Link, StaticQuery } from 'gatsby'
import styles from './carousell.module.css'
import Navigation from './navigation'
// import Carousel from 'react-material-ui-carousel'
import { Button, Paper } from '@material-ui/core'

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { navigate } from '@reach/router';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
export default function Carousell() {
    return (<StaticQuery<GatsbyTypes.CarousellQuery>
        query={graphql`
    query Carousell {
        allContentfulFrontPageModal {
            edges {
              node {
                id
                heading
                imageSubHeading
                order
                image{
                    fluid(maxWidth: 1600) {
                        src
                        srcSet
                    }
                }
              }
            }
          }
    }
  `}
        render={(data: GatsbyTypes.CarousellQuery) => {
            let items = data.allContentfulFrontPageModal.edges;


            return (
                <>

                    {/* <GatsbyImage src={image.image?.fixed?.src} alt="test" /> */}

                    <Carousel 
                    className={styles.carousellRoot}  
                    infiniteLoop={true} 
                    transitionTime={1000} 
                    interval={5500} 
                    autoPlay={true}
                    // renderArrowNext={(clickHandler,hasNext, label) => <ArrowBackIcon></ArrowBackIcon>}	
                    >
                        {
                            items.map((item, i) => <Item key={i} item={item.node} />)
                        }
                        {/* <Item key={0} item={items[0].node}></Item> */}
                    </Carousel>
                </>
            )
        }}
    />)
}



type ItemProps = {
    item: any,
}

function Item({ item }: ItemProps) {
    return (
        // <Paper>
        <div className={styles.container}>
            <div className={styles.textContainer}>

                <h1 className={styles.heading}>{item.heading}</h1>
                <p className={styles.subHeading}>{item.imageSubHeading}</p>
                <Button className={styles.button} variant="contained" color="primary"
                onClick={(e) => navigate("/7-easy-steps-to-plan-upgrade-to-iso-9001-2015")}
                >
                    Check it out!
                </Button>
            </div>

            <img className={styles.image} src={item.image.fluid.src} alt="test" />

        </div>
        // </Paper>
    )
}