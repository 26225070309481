import React from 'react'
import { GatsbyGraphQLType, graphql, Link, StaticQuery } from 'gatsby'
import styles from './services.module.css'
import Navigation from './navigation'
import Carousel from 'react-material-ui-carousel'
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Paper, Typography } from '@material-ui/core'
import { navigate } from '@reach/router';

import { GatsbyImage, getImage } from "gatsby-plugin-image"



export default function Services() {
    return (<StaticQuery<GatsbyTypes.ServicesQuery>
        query={graphql`
    query Services {
          allContentfulServices {
            edges {
              node {
                id
                title
                image {
                  id
                  fluid(maxWidth: 1600) {
                        src
                        srcSet
                  }
                }
                fullPage {
                  id
                }
                slug
              }
            }
          }
    }
  `}
        render={(data: GatsbyTypes.ServicesQuery) => {
            let items = data.allContentfulServices.edges;


            return (
                <>
                    <div className={styles.headerContainer}>
                        <h1 className={styles.heading}>Our Services</h1>
                    </div>
                    <div className={styles.container}>
                        {
                            items.map((item, i) => <Item key={i} item={item.node} />)
                        }
                    </div>
                </>
            )
        }}
    />)
}



type ItemProps = {
    item: any,
}

function Item({ item }: ItemProps) {
    return (
        <div className={styles.itemContainer}>
            <Card className={styles.root}>
                <CardActionArea onClick={() => {  navigate(item.slug);}}>

                    {/* <h1 className={styles.heading}>{item.title}</h1>
            <img className={styles.image} src={item.image.fluid.src} alt="test" /> */}
                    <CardMedia
                        className={styles.media}
                        image={item.image.fluid.src}
                        title={item.slug}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            {item.title}
                        </Typography>
                        {/* <Typography variant="body2" color="textSecondary" component="p">
                            Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                            across all continents except Antarctica
                        </Typography> */}
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    {/* <Button size="small" color="primary">
                        Share
                    </Button>
                    <Button size="small" color="primary">
                        Learn More
                    </Button> */}
                </CardActions>
            </Card>
        </div>


    )
}