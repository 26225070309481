import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import { Link, PageProps } from "gatsby"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Navigation from '../components/navigation'


import { Button } from '@material-ui/core';
import Carousell from '../components/carousell'
import Services from '../components/services'



export default function IndexRoute(props: PageProps<GatsbyTypes.HomePageQuery, GatsbyTypes.SitePageContext>) {
  return (
    <>
      <Helmet>
        <title>Lean Quality Academy</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Layout shouldContainer={false}>
        {/* <Button color="primary" variant="contained" >Test</Button> */}
        <Carousell />
        <Services />

      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query HomePage{
    allContentfulPage {
      edges {
        node {
          title
          slug
          body {
            json
          }
        }
      }
    }
  }`